<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import {
  VxCellDate,
  VxCellGiftKeyStatus,
  VxCellLink,
  VxCellMoney,
  VxTable
} from '@/components/table'
import { passDataToResource, giftCards } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'

export default {
  name: 'GiftCardsDetails',
  components: {
    VxTable,
    VxCellDate,
    VxCellMoney,
    VxCellLink,
    VxCellGiftKeyStatus
  },
  setup () {
    const resource = passDataToResource(giftCards.details, {
      requestParams: {
        params: { include: 'payment' }
      }
    })

    const columns = [
      {
        key: 'kinguin_order_id',
        label: 'Order id'
      },
      {
        key: 'code'
      },
      {
        key: 'brutto_amount',
        label: 'Price',
        component: VxCellMoney
      },
      {
        key: 'gate_amount',
        label: 'Gateway',
        component: VxCellMoney
      },
      {
        key: 'payment.id',
        label: 'Payment id',
        component: VxCellLink,
        tdAttr: (id) => {
          if (!id) return null
          return {
            label: `#${id}`,
            href: buildRoute(path.payments, { query: { id } })
          }
        }
      },
      {
        key: 'status',
        sortable: true,
        component: VxCellGiftKeyStatus
      },
      {
        key: 'created_at',
        sortable: true,
        defaultSort: sortOrders.asc,
        component: VxCellDate
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      }
    ]

    const filters = [
      { key: 'kinguin_order_id', label: 'Order id' },
      { key: 'code' },
      { key: 'payment_id' },
      {
        key: 'status',
        type: filterTypes.serverSelect,
        resource: giftCards.statuses,
        searchable: false
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
